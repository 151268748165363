import { SxProps } from "@mui/material";
import { Box } from "../../atoms";
import { forwardRef } from "react";

export const ModalContent = forwardRef(function ModalContentBase(
  {
    children,
    sx,
  }: {
    children: React.ReactNode;
    sx?: SxProps;
  },
  ref
) {
  return (
    <Box
      ref={ref}
      sx={{
        py: 2,
        px: 3,
        overflowWrap: "break-word",
        overflowY: "auto",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
});
