import { Avatar, Box, Typography } from "../../atoms";
import { EllipsisWithTooltip } from "../EllipsisWithTooltip/EllipsisWithTooltip";

interface UserAvatarProps {
  users: string[];
  disableTooltip?: boolean;
  emptyStateMessage?: string;
}

export function UsersAvatar({
  users = [],
  disableTooltip = false,
  emptyStateMessage = "-",
}: UserAvatarProps) {
  const isMoreUsersThanTwo = users.length > 2;
  const usersToDisplay = isMoreUsersThanTwo ? users?.slice(0, 2) : [...users];
  const avatars: React.ReactNode[] = [];

  usersToDisplay.forEach((user, index) => {
    const color = index > 0 ? "green" : "yellow";
    avatars.push(
      <Avatar
        key={user}
        sx={{
          width: "18px",
          height: "18px",
          fontSize: "0.75rem",
          backgroundColor: `${color}.100`,
          color: `${color}.700`,
          zIndex: 3 - index,
          ...(index > 0 ? { ml: -0.25 } : {}),
        }}
      >
        {user?.charAt(0)}
      </Avatar>
    );
  });

  if (isMoreUsersThanTwo) {
    avatars.push(
      <Avatar
        key={"MORE USERS"}
        sx={{
          width: "18px",
          height: "18px",
          fontSize: "0.75rem",
          backgroundColor: `grey.200`,
          color: `grey.700`,
          zIndex: 1,
          ml: -0.25,
        }}
      >
        +{users.length - 2}
      </Avatar>
    );
  }

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: "8px", width: "100%" }}
    >
      <Box sx={{ display: "flex" }}>{avatars}</Box>
      <Typography
        variant="bodyMedium14"
        sx={{ overflow: "hidden", width: "100%" }}
      >
        <EllipsisWithTooltip disableTooltip={disableTooltip}>
          {users.join(", ") || emptyStateMessage}
        </EllipsisWithTooltip>
      </Typography>
    </Box>
  );
}
