import { UserDto } from "../apiTypes";
export enum ROLES {
  OWNER = "owner",
  COLLABORATOR = "collaborator",
  APPROVER = "approver",
  EXTERNAL = "externalVendor",
}

export enum UCM_ROLE {
  ADMIN = "admin",
  PORTFOLIO_MANAGER = "portfolio_manager",
  STANDARD_USER = "standard",
  COMPLIANCE_OFFICER = "compliance_officer",
  EXTERNAL_VENDOR = "external_vendor",
}
export interface UserWithRole extends UserDto {
  role: ROLES;
}
