import { Box, Link, Typography, Divider } from "@applied-ai/components";
import Image from "next/image";
import Logo from "@applied-ai/root/public/images/logo.svg";
import { useTranslation } from "next-i18next";

const FEVersion = process.env.NEXT_PUBLIC_FE_VERSION || "";
const BEVersion = process.env.NEXT_PUBLIC_BE_VERSION || "";

export const LINK_STYLE = {
  color: "primary.500",
  height: "100%",
  fontSize: "0.75rem",

  "&:visited, &:active, &:focus": {
    color: "primary.500",
    textDecoration: "none",
  },
  "&:hover": {
    color: "primary.600",
    textDecoration: "underline",
  },
};

const MENU_ITEMS = [
  {
    label: "help.privacyPolicy",
    url: "https://www.appliedai.de/en/privacy-policy/",
  },
  {
    label: "help.aboutUs",
    url: "https://www.appliedai.de/en/philosophy/",
  },
  {
    label: "help.imprint",
    url: "https://www.appliedai.de/en/imprint/",
  },
  {
    label: "help.help",
    url: "",
  },
];

export const Footer = () => {
  const { t } = useTranslation(["common"]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        zIndex: 1000,
        backgroundColor: "common.white",
        px: "24px",
        py: "12px",
        borderTop: "1px solid",
        borderColor: "grey.200",
        height: "48px",
        marginTop: "auto",
      }}
      component="footer"
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
        {MENU_ITEMS.map(({ label, url }, index) => (
          <Link
            href={url}
            target="_blank"
            rel="noreferrer"
            size="large"
            sx={LINK_STYLE}
            key={index}
          >
            {t(label)}
          </Link>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Box sx={{ display: "none" }}>
            App version: {FEVersion} ({BEVersion})
          </Box>
          <Typography variant="bodyRegular12" sx={{ color: "grey.600" }}>
            {FEVersion}
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          sx={{ borderColor: "grey.200", height: "24px" }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <Typography variant="bodyRegular12">{t("help.by")}</Typography>
          <Link
            href="https://www.appliedai.de/en/"
            target="_blank"
            rel="noreferrer"
          >
            <Image src={Logo} alt="AAI logo" width={95} height={24} />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
