import { ROLES, UserDto, UserWithRole } from "@applied-ai/types";
import { Dispatch, SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { getDisplayName } from "@applied-ai/utils";
import { SharedUser } from "./SharedUser";
import { useTranslation } from "next-i18next";
import { OwnersAndCollaboratorsInput } from "./InviteUsersInput/OwnersAndCollaboratorsInput";
import { isUserOwnerOrCollaborator } from "./userRoleUtils";

interface OwnersAndCollaboratorsTabProps<User> {
  isVisible: boolean;
  initialUsers: User[];
  getUsers: (q: string) => Promise<UserDto[]>;
  getOptionDisabled: (user: UserDto) => boolean;
  addUsers: (users: string[], role: ROLES, successCallback: () => void) => void;
  canEditUser: (user: UserWithRole, users: UserWithRole[]) => boolean;
  toggleParentVisibility: (isVisible: SetStateAction<boolean>) => void;
  removeUser: (
    userId: string,
    users: UserWithRole[],
    updateUsers: React.Dispatch<React.SetStateAction<User[]>>
  ) => () => void;
  disabled: boolean;
  canRemoveUser: boolean;
  canAssignRoles: boolean;
  label?: string;
  onRoleChange?: (
    userId: string,
    users: UserWithRole[],
    updateUsers: React.Dispatch<React.SetStateAction<User[]>>
  ) => (role: ROLES) => Promise<void>;
  defaultRole?: ROLES;
  hideChangeRoleSelect?: boolean;
}

export const OwnersAndCollaboratorsTab = <User extends UserWithRole>({
  isVisible,
  initialUsers,
  getUsers,
  addUsers,
  onRoleChange,
  getOptionDisabled,
  canEditUser,
  toggleParentVisibility,
  removeUser,
  disabled,
  canRemoveUser,
  canAssignRoles,
  label,
  defaultRole = ROLES.COLLABORATOR,
  hideChangeRoleSelect,
}: OwnersAndCollaboratorsTabProps<User>) => {
  const [users, setUsers] = useState<User[]>(initialUsers);
  const { t } = useTranslation("common");
  const menuItems = [
    { value: ROLES.OWNER, label: t("owner") },
    { value: ROLES.COLLABORATOR, label: t("collaborator") },
  ];

  if (!isVisible) return null;

  const isOptionOwnerOrCollaborator = (user: UserDto, users: UserWithRole[]) =>
    !!users.find((assignedUsers: UserWithRole) => assignedUsers.id === user.id);

  return (
    <>
      <OwnersAndCollaboratorsInput
        defaultRole={defaultRole}
        initialUsers={initialUsers}
        users={users}
        setUsers={setUsers}
        getUsers={getUsers}
        getOptionDisabled={(user: UserDto, users: UserWithRole[]) =>
          getOptionDisabled(user) || isOptionOwnerOrCollaborator(user, users)
        }
        addUsers={addUsers}
        canAssignRoles={canAssignRoles}
        label={label}
        hideChangeRoleSelect={hideChangeRoleSelect}
      />
      <>
        <Divider sx={{ my: "16px" }} />
        <Box
          sx={{
            border: "1px solid",
            borderColor: "grey.100",
            borderRadius: 1,
            py: 1,
            mt: 2,
            gap: 1,
          }}
          data-testid="share-modal-owners-and-collaborators-section"
        >
          {users
            .sort((userA, userB) =>
              getDisplayName(userA).localeCompare(getDisplayName(userB))
            )
            .filter(isUserOwnerOrCollaborator)
            .map((user) => {
              return (
                <SharedUser
                  users={users}
                  roleToDisplay={t(`${user.role}s`)}
                  modalTitle={t("deleteUser")}
                  toggleParentVisibility={() =>
                    toggleParentVisibility((prevValue) => !prevValue)
                  }
                  disabled={disabled}
                  areActionsVisible={canEditUser(user, users)}
                  avatar="invalid_link"
                  key={user.id}
                  user={user}
                  canRemove={canRemoveUser}
                  remove={removeUser(user.id, users, setUsers)}
                  onRoleChange={onRoleChange?.(user.id, users, setUsers)}
                  menuItems={
                    hideChangeRoleSelect
                      ? menuItems.filter(
                          ({ value, label }) => value === defaultRole
                        )
                      : menuItems
                  }
                />
              );
            })}
        </Box>
      </>
    </>
  );
};
