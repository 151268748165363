import { SyntheticEvent, useEffect, useState } from "react";
import { Box } from "../../reexports/Box";
import { SingleCheckboxItem } from "../../components";

type Option = {
  id: string;
  name: string;
};

interface UcmCheckboxGroupProps {
  options?: Option[];
  defaultValue: string[];
  onChange: (value: string[]) => void;
  disabled: boolean;
}

const itemStyles = {
  ".MuiFormControlLabel-label": {
    width: "100%",
  },
};

export function UcmCheckboxGroup({
  options,
  defaultValue,
  onChange,
  disabled,
}: UcmCheckboxGroupProps) {
  const [selectedAnswers, setSelectedAnswers] = useState<string[]>(
    defaultValue || []
  );

  useEffect(() => {
    setSelectedAnswers(defaultValue);
  }, [defaultValue]);

  const onChangeHandler = (e: SyntheticEvent<Element, Event>) => {
    const target = e.target as HTMLInputElement;
    const id = target.value;

    setSelectedAnswers((prevSelectedAnswers) => {
      const isSelected = prevSelectedAnswers.includes(id);
      const newSelectedAnswers = isSelected
        ? prevSelectedAnswers.filter((answer) => answer !== id)
        : [...prevSelectedAnswers, id];

      onChange(newSelectedAnswers);
      return newSelectedAnswers;
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
      data-testid="select-wrapper"
    >
      {options?.map((option) => {
        const isChecked = selectedAnswers.includes(option.id);

        return (
          <SingleCheckboxItem
            key={option.id}
            label={option.name}
            value={option.id}
            checked={isChecked}
            onChange={onChangeHandler}
            disabled={disabled}
            sx={itemStyles}
            data-testid={`single-checkbox-item-${option.id}`}
          />
        );
      })}
    </Box>
  );
}
