import { ReactNode, useLayoutEffect, useRef, useState } from "react";
import { Tooltip } from "../../atoms";
import { Box, SxProps, styled } from "../../reexports";
import { Theme } from "@mui/material/styles/createTheme";

interface Props {
  children: ReactNode;
  disableTooltipEvents?: boolean;
  disableTooltip?: boolean;
  clamp?: number;
  breakWord?: boolean;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  tooltipSx?: SxProps<Theme>;
}

const Content = styled(Box)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const EllipsisWithTooltip = ({
  children,
  placement,
  clamp = 1,
  disableTooltipEvents = false,
  disableTooltip = false,
  breakWord = true,
  tooltipSx,
}: Props) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    const { scrollHeight, scrollWidth, clientWidth, clientHeight } =
      textElementRef?.current || {};

    let isVerticalOverflow = false;
    let isHorizontalOverflow = false;

    if (scrollHeight !== undefined && clientHeight !== undefined) {
      isVerticalOverflow = scrollHeight > clientHeight;
    }
    if (scrollWidth !== undefined && clientWidth !== undefined) {
      isHorizontalOverflow = scrollWidth > clientWidth;
    }

    setIsOverflow(isVerticalOverflow || isHorizontalOverflow);
  }, [children]);

  return (
    <Tooltip
      sx={{
        wordBreak: "break-all",
        pointerEvents: disableTooltipEvents ? "none" : "auto",
        ...tooltipSx,
      }}
      title={children}
      disableHoverListener={!isOverflowed || disableTooltip}
      placement={placement}
    >
      <Content
        sx={{
          WebkitLineClamp: clamp,
          wordBreak: breakWord ? "break-all" : "normal",
        }}
        ref={textElementRef}
      >
        {children}
      </Content>
    </Tooltip>
  );
};
