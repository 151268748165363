import { Control, Controller, FieldValues, Path } from "react-hook-form";

import { UcmSelect, UcmSelectProps } from "./UcmSelect";

export function UcmSelectWithController<T extends FieldValues>({
  type,
  id,
  label,
  control,
  disabled,
  required,
  error,
  ...props
}: {
  control: Control<T>;
} & Omit<UcmSelectProps, "onChange" | "value">) {
  return (
    <Controller
      name={id as Path<T>}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      control={control}
      rules={{
        required,
      }}
      render={({ field }) => {
        return (
          <UcmSelect
            {...field}
            disabled={disabled}
            label={label}
            error={!!error}
            {...props}
          />
        );
      }}
    />
  );
}
