import { ROLES, UserDto, UserWithRole } from "@applied-ai/types";
import Check from "@mui/icons-material/Check";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "next-i18next";
import { Button, Typography } from "../../../atoms";
import { Box, MenuItem } from "../../../reexports";
import { DataList } from "../../DataList/DataList";
import { useInviteUsers } from "./useAssignedUsers";

interface ExternalsInput<User> {
  initialUsers: User[];
  users: User[];
  setUsers: (users: User[]) => void;
  getUsers: (q: string) => Promise<UserDto[]>;
  getOptionDisabled: (user: UserDto, users: UserWithRole[]) => boolean;
  addUsers: (users: string[], role: ROLES, successCallback: () => void) => void;
  canAssignExternal: boolean;
}

export const ExternalsInput = <User extends UserWithRole>({
  initialUsers,
  users,
  setUsers,
  getUsers,
  addUsers,
  getOptionDisabled,
  canAssignExternal,
}: ExternalsInput<User>) => {
  const { t } = useTranslation("common");
  const {
    assignedUsers,
    assignedUsersIds,
    handleInviteUsersClick,
    handleAssignUsersClick,
    setAssignedUsers,
  } = useInviteUsers(ROLES.EXTERNAL, users, setUsers, addUsers);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "8px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <DataList
          enableSearchOnClick
          disabled={!canAssignExternal}
          value={assignedUsers}
          getOptions={getUsers}
          getOptionLabel={(userDto: UserDto) =>
            `${userDto.details.email}_${userDto.details.display_name}`
          }
          isSelected={(option: UserDto, value: UserDto) =>
            assignedUsersIds.includes(option.id)
          }
          autoFocus
          popupIcon={null}
          getOptionDisabled={(user: UserDto) => getOptionDisabled(user, users)}
          initialValue={initialUsers}
          listBoxDataTestId="external-autocomplete-options"
          renderOption={(props, option, state: { selected?: boolean }) => {
            const onClick = handleAssignUsersClick(option, state);
            return (
              <MenuItem
                {...props}
                onClick={onClick}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start !important",
                  }}
                >
                  <Typography variant="bodyMedium14">
                    {option.details.display_name}
                  </Typography>
                  <Typography variant="bodyMedium12" sx={{ color: "grey.600" }}>
                    {option.details.email}
                  </Typography>
                </Box>
                {state?.selected && (
                  <Box sx={{ marginLeft: "auto" }}>
                    <Check />
                  </Box>
                )}
              </MenuItem>
            );
          }}
          onChangeCallback={(users: User[] | UserDto[]) => {
            setAssignedUsers(users);
          }}
          renderTags={"TAGS"}
          placeholder={t("emailOrName")}
          getTagLabel={(user) => user.details.display_name}
          icon={<SearchOutlined />}
          clearOnSelect={false}
          listBoxHeigh="15rem"
        />
      </Box>
      <Button
        variant="contained"
        sx={{
          marginTop: "24px",
          whiteSpace: "nowrap",
        }}
        onClick={handleInviteUsersClick}
        disabled={!canAssignExternal}
      >
        {t("common:invite")}
      </Button>
    </Box>
  );
};
