import { Container as Wrapper, styled } from "@applied-ai/components";
import { AppsConfigPayload, Page } from "@applied-ai/types";
import { Session } from "next-auth/core/types";
import { Footer } from "../Footer/Footer";
import { Navbar } from "../Navbar/Navbar";

const Content = styled("div")`
  height: 100vh;
  overflow: auto;
`;

const Container = styled("div")`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const NavbarWithContentLayout: React.FC<{
  children: React.ReactNode;
  session?: Session | null;
  pages?: Page[];
  displayFooter?: boolean;
  config?: AppsConfigPayload;
}> = ({ children, session, pages, displayFooter = false, config }) => (
  <Container>
    <Content>
      <Navbar session={session} pages={pages} config={config} />
      <Wrapper>{children}</Wrapper>
    </Content>
    {displayFooter && <Footer />}
  </Container>
);
