import { TextFieldProps } from "@mui/material";
import React from "react";
import { Control, Controller, Path, RegisterOptions } from "react-hook-form";
import { TextField } from "./TextField";
import { FieldValues } from "react-hook-form";

export function TextFieldWithController<T extends FieldValues>({
  disabled,
  label,
  placeholder,
  error,
  id,
  type,
  control,
  required,
  rules,
  ...rest
}: {
  control: Control<T>;
  rules?:
    | Omit<
        RegisterOptions<T, Path<T>>,
        "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
      >
    | undefined;
} & TextFieldProps) {
  return (
    <Controller
      name={id as Path<T>}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      control={control}
      rules={{
        required,
        ...rules,
      }}
      render={({ field }) => {
        return (
          <TextField
            {...field}
            disabled={disabled}
            type={type}
            label={label}
            placeholder={placeholder}
            error={!!error}
            inputProps={{
              min: 0,
              valueAsNumber: true,
            }}
            {...rest}
          />
        );
      }}
    />
  );
}
