import InputLabel from "@mui/material/InputLabel";
import MuiTextField from "@mui/material/TextField";
import {
  TextFieldProps,
  styled,
  alpha,
  useTheme,
  SxProps,
} from "@mui/material";
import { Typography } from "../Typography/Typography";
import { forwardRef } from "react";
import { theme } from "../../theme/theme";
import { Box } from "../../reexports/Box";

export const hoverStyles = {
  border: `1px solid ${theme.palette.primary[500]}`,
};

export const StyledTextField = styled(MuiTextField)`
  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }

  & .MuiInputBase-root {
    color: ${theme.palette.grey[700]};
    background-color: ${theme.palette.grey[50]};
    font-weight: 400;
    padding: ${(props) => (props.select ? "0px" : "8px 12px 8px 8px")};
    border: 1px solid ${theme.palette.grey[300]};
    border-radius: 4px;
  }
  & .MuiInputBase-root:hover {
    ${hoverStyles}
  }
  & .MuiInputBase-input {
    padding: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  & .MuiInputBase-input::placeholder {
    color: ${theme.palette.grey[500]};
    opacity: 1;
  }
  & .MuiInputBase-root.Mui-focused {
    border: 1px solid ${theme.palette.primary[500]};
    box-shadow: 0px 0px 4px 0px ${alpha(theme.palette.primary[500], 0.6)};
  }
  // Input wrapper
  & .MuiInputBase-root.Mui-disabled {
    background-color: ${theme.palette.grey[100]};
    cursor: not-allowed;
  }

  & .MuiInputBase-root.Mui-disabled:hover {
    border: 1px solid ${theme.palette.grey[300]};
  }

  // Input
  & .MuiInputBase-root .Mui-disabled {
    cursor: not-allowed;
    -webkit-text-fill-color: ${theme.palette.grey[700]};
  }
  & .MuiInputBase-root .Mui-disabled::placeholder {
    -webkit-text-fill-color: ${theme.palette.grey[500]};
  }

  & .MuiInputBase-root.Mui-error {
    border: 1px solid ${theme.palette.red[500]};

    &.Mui-focused {
      box-shadow: 0px 0px 4px 0px ${alpha(theme.palette.red[500], 0.6)};
    }
  }

  & .MuiInputBase-root.Mui-error:hover {
    border: 1px solid ${(props) => props.theme.palette.red[500]};
  }

  & input[type="number"] {
    appearance: textfield /* Remove default appearance */;
  }
  & input[type="number"]::-webkit-inner-spin-button,
  & input[type="number"]::-webkit-outer-spin-button {
    appearance: none /* Remove default appearance */;
  }

  & .MuiSelect-select {
    padding: 8px 12px 8px 8px;
  }
`;

export const TextField: React.FC<
  TextFieldProps & {
    labelSx?: SxProps;
  }
> = forwardRef(
  (
    { label, required, fullWidth, helperText, labelSx, inputProps, ...rest },
    ref
  ) => {
    const theme = useTheme();

    return (
      <InputLabel sx={{ ...labelSx, overflow: "visible" }}>
        {label && (
          <Typography
            variant="bodyMedium14"
            sx={{
              color: theme.palette.grey[700],
              display: "flex",
              paddingBottom: "4px",
            }}
          >
            {label}
            {required ? <Box color="red.500">*</Box> : null}
          </Typography>
        )}
        <StyledTextField
          helperText={helperText}
          required={required}
          ref={ref}
          label={undefined}
          InputProps={{
            ...rest.InputProps,
          }}
          inputProps={{
            "data-testid": "inputs",
            ...(inputProps || {}),
            ...(rest.type === "number"
              ? {
                  onWheel: (e) => {
                    e.currentTarget.blur();
                  },
                  min: "0",
                }
              : {}),
          }}
          fullWidth={fullWidth}
          FormHelperTextProps={{
            sx: {
              margin: 0,
              padding: "4px 0 0 4px",
              color: `${theme.palette.red[500]} !important`,
              whiteSpace: "pre-line",
            },
            ...rest.FormHelperTextProps,
          }}
          {...rest}
        />
      </InputLabel>
    );
  }
);
