import ArrowBack from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material/styles";
import React, { ReactNode } from "react";
import { Button } from "../../atoms";
import { Box } from "../../reexports/Box";
import { Divider } from "../../reexports/Divider";

interface FormHeaderProps {
  statusComponent: ReactNode;
  stagesComponent?: ReactNode;
  actionElements: ReactNode[];
  titleComponent: ReactNode;
  onBackButtonClick: () => void;
  titleError?: boolean;
}

const Grid = styled("header")<{ titleError?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 72px;
  padding: 12px 24px 12px 16px;
  padding-bottom: ${(p) => (p.titleError ? "24px" : "12px")};
`;

const ContentWrapper = styled("div")`
  display: flex;
  align-items: center;
  align-self: center;
`;

export const FormHeader: React.FC<FormHeaderProps> = ({
  statusComponent,
  stagesComponent,
  actionElements,
  titleComponent,
  onBackButtonClick,
  titleError,
}) => {
  const filteredActionElements = actionElements.filter(
    (actionElement) => !!actionElement
  );

  return (
    <Grid titleError={titleError}>
      <Button
        icon={
          <ArrowBack
            sx={{
              color: "grey.700",
              width: "20px",
              height: "20px",
              position: "relative",
              top: "1px",
            }}
          />
        }
        variant="text"
        size="small"
        onClick={onBackButtonClick}
        sx={{
          width: "36px",
          height: "36px",
          padding: "8px",
          alignSelf: "flex-start",
        }}
      />
      {titleComponent}

      <ContentWrapper>{statusComponent}</ContentWrapper>
      <ContentWrapper>{stagesComponent}</ContentWrapper>
      <ContentWrapper>
        <Divider
          key="divider"
          orientation="vertical"
          sx={{
            mx: "16px",
            height: "36px",
            backgroundColor: "grey.200",
          }}
        />
      </ContentWrapper>
      {filteredActionElements.map((element, index) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ml: "12px",
            alignSelf: "center",
          }}
          key={`${element}_${index}`}
        >
          {element}
        </Box>
      ))}
    </Grid>
  );
};
