import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import {
  useTheme,
  BaseSelectProps,
  SxProps,
  SelectChangeEvent,
} from "@mui/material";
import { Typography } from "../../Typography/Typography";
import { Box } from "../../../reexports";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "next-i18next";
import { BaseSelect } from "../BaseSelect";
import Check from "@mui/icons-material/Check";

export interface UcmSelectProps extends Omit<BaseSelectProps, "onChange"> {
  label?: string;
  labelSx?: SxProps;
  helperText?: string;
  fullWidth?: boolean;
  options: { label: string; value: string }[];
  onChange: (values: string) => void;
  value: string;
  required?: boolean;
}

export const UcmSelect: React.FC<UcmSelectProps> = forwardRef(
  (
    {
      label,
      required = false,
      labelSx,
      helperText,
      fullWidth,
      options,
      onChange,
      value,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation("common");
    const theme = useTheme();

    const onChangeHandler = (
      event: SelectChangeEvent<unknown>,
      _?: ReactNode
    ) => onChange?.(event.target.value as string);

    return (
      <Box>
        <InputLabel sx={{ ...labelSx, overflow: "visible" }}>
          {label && (
            <Typography
              variant="bodyMedium14"
              sx={{
                color: theme.palette.grey[700],
                display: "flex",
                paddingBottom: "4px",
              }}
            >
              {label}
              {required ? <Box color="red.500">*</Box> : null}
            </Typography>
          )}

          <BaseSelect
            required={required}
            ref={ref}
            onChange={onChangeHandler}
            IconComponent={KeyboardArrowDown}
            fullWidth={fullWidth}
            displayEmpty
            value={value}
            renderValue={(values) => {
              const isEmpty = !values || !(values as string[])?.length;
              const label =
                options?.find((option) => option.value === value)?.label ||
                t("common:selectYourAnswer");

              return (
                <Typography
                  color={isEmpty ? "grey.500" : "grey.900"}
                  variant="bodyRegular14"
                  sx={{ lineHeight: "24px" }}
                >
                  {label}
                </Typography>
              );
            }}
            {...rest}
          >
            {options?.map((option) => {
              const isSelected = option.value === value;

              return (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  data-testid="single-choice-dropdown-option"
                  onClick={() => isSelected && onChange("")}
                >
                  <Box
                    sx={{
                      gap: "8px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="bodyRegular14">
                      {option.label}
                    </Typography>
                    {isSelected && <Check sx={{ ml: 2 }} />}
                  </Box>
                </MenuItem>
              );
            })}
          </BaseSelect>
          <FormHelperText
            error={rest.error}
            sx={{ color: `${theme.palette.red[500]} !important` }}
          >
            {helperText}
          </FormHelperText>
        </InputLabel>
      </Box>
    );
  }
);
