import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import {
  useTheme,
  BaseSelectProps,
  SxProps,
  SelectChangeEvent,
} from "@mui/material";
import { Typography } from "../../Typography/Typography";
import { Box } from "../../../reexports";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { forwardRef, ReactNode } from "react";
import { useTranslation } from "next-i18next";
import { Tag } from "../../Tag/Tag";
import Close from "@mui/icons-material/Close";
import { BaseSelect } from "../BaseSelect";

interface MultiselectProps extends Omit<BaseSelectProps, "onChange"> {
  options: { label: string; value: string }[];
  onChange: (values: string[]) => void;
  value: string[];
  label?: string;
  labelSx?: SxProps;
  helperText?: string;
  fullWidth?: boolean;
  required?: boolean;
  placeholder?: string;
}

export const UcmMultiselect: React.FC<MultiselectProps> = forwardRef(
  (
    {
      label,
      required = false,
      labelSx,
      helperText,
      fullWidth,
      options,
      onChange,
      value,
      placeholder,
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation("common");
    const theme = useTheme();

    const onChangeHandler = (
      event: SelectChangeEvent<unknown>,
      _?: ReactNode
    ) => onChange?.(event.target.value as string[]);

    return (
      <InputLabel sx={{ ...labelSx, overflow: "visible" }}>
        {label && (
          <Typography
            variant="bodyMedium14"
            sx={{
              color: theme.palette.grey[700],
              display: "flex",
              paddingBottom: "4px",
            }}
          >
            {label}
            {required ? <Box color="red.500">*</Box> : null}
          </Typography>
        )}
        <BaseSelect
          multiple={true}
          required={required}
          ref={ref}
          onChange={onChangeHandler}
          IconComponent={KeyboardArrowDown}
          fullWidth={fullWidth}
          displayEmpty
          value={value}
          renderValue={(values) => {
            if (!values || !(values as string[])?.length) {
              return (
                <Typography
                  color="grey.500"
                  variant="bodyRegular14"
                  sx={{ lineHeight: "24px" }}
                >
                  {placeholder || t("common:selectYourAnswers")}
                </Typography>
              );
            }

            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
                {(values as string[])?.map((value) => {
                  const selected = options?.find(
                    (option) => option.value === value
                  );

                  if (!selected) {
                    return null;
                  }

                  return (
                    <Tag
                      key={selected.value}
                      label={selected.label}
                      borderColor={theme.palette.grey[300]}
                      backgroundColor={theme.palette.grey[100]}
                      onDelete={() =>
                        onChange?.(
                          (values as string[]).filter((v) => v !== value)
                        )
                      }
                      sx={{
                        color: theme.palette.grey[700],
                      }}
                      deleteIcon={
                        <Close
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  );
                })}
              </Box>
            );
          }}
          {...rest}
        >
          {options?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              sx={{ gap: "8px" }}
              data-testid="multi-choice-dropdown-option"
            >
              <Checkbox
                checked={value.indexOf(option.value) > -1}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: "20px" },
                  width: "20px",
                  height: "20px",
                }}
              />
              <Typography variant="bodyRegular14">{option.label}</Typography>
            </MenuItem>
          ))}
        </BaseSelect>
        <FormHelperText
          error={rest.error}
          sx={{ color: `${theme.palette.red[500]} !important` }}
        >
          {helperText}
        </FormHelperText>
      </InputLabel>
    );
  }
);
