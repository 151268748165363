import {
  Button,
  TextFieldWithController,
  TextFieldWithCounter,
  UcmSelectWithController,
} from "../../atoms";
import { Box, Modal, styled } from "../../reexports";
import { useTranslation } from "next-i18next";
import { useEffect } from "react";
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalWrapper,
} from "../ModalComponents";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { ToastVariants, useToastStore } from "@applied-ai/stores";
import { QuestionTableColumnProps, QuestionTableRowProps } from "./types";
import {
  parseEditValues,
  getDefaultValues,
  parseColumnsAndValuesIds,
} from "./dataParsers";
import { EditionMode } from "./types";

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export function TableRowCreationModal({
  isModalOpen = false,
  closeModal,
  columns,
  onSubmitHandler,
  editData,
  mode,
  resetError,
  error,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  columns: QuestionTableColumnProps[];
  onSubmitHandler: (
    modifiedRow: QuestionTableRowProps,
    mode: EditionMode
  ) => Promise<void>;
  editData: QuestionTableRowProps | null;
  resetError: () => void;
  mode: EditionMode;
  error?: string;
}) {
  const { t } = useTranslation(["common"]);
  const { addToast } = useToastStore();
  const {
    handleSubmit,
    reset,
    formState: { isValid, isDirty, errors },
    control,
    watch,
  } = useForm({
    defaultValues: editData
      ? parseEditValues(editData)
      : getDefaultValues(columns),
    mode: "onChange",
  });
  const allFields = watch();
  const isFormEmpty = Object.values(allFields).filter((v) => !!v).length === 0;

  useEffect(() => {
    if (error) {
      addToast({
        type: ToastVariants.ERROR,
        message: error || t("common:somethingWentWrongTryAgain"),
        position: { vertical: "top", horizontal: "right" },
        closeCallback: resetError,
      });
    }
  }, [error, addToast, t, resetError]);

  useEffect(() => {
    reset(editData ? parseEditValues(editData) : getDefaultValues(columns));
  }, [columns, editData, reset, isModalOpen]);

  const onSubmit = async (data: Record<string, string>) => {
    let dataToSend;

    if (editData && mode !== "delete") {
      const columnAndValuesIds = parseColumnsAndValuesIds(editData);

      dataToSend = {
        row_id: editData.row_id,
        values: Object.entries(data).map(([column_id, cellValue]) => ({
          column_id,
          value_id: columnAndValuesIds[column_id] || uuidv4(),
          value: cellValue || null,
        })),
      };
    }

    if (editData && mode === "delete") {
      dataToSend = {
        row_id: editData.row_id,
        values: [],
      };
    }

    if (mode === "create") {
      dataToSend = {
        row_id: uuidv4(),
        values: Object.entries(data).map(([column_id, cellValue]) => ({
          column_id,
          value_id: uuidv4(),
          value: cellValue || null,
        })),
      };
    }

    if (!dataToSend) {
      return;
    }

    await onSubmitHandler(dataToSend, mode);
  };

  const TEXTS: Record<EditionMode, Record<string, string>> = {
    create: {
      title: t("common:addRow"),
      actionButtonLabel: t("common:add"),
    },
    edit: {
      title: t("common:updateRow"),
      actionButtonLabel: t("common:update"),
    },
    delete: {
      title: t("common:deleteRow"),
      actionButtonLabel: t("common:delete"),
    },
  };

  const ERROR_MESSAGES = {
    min: t("common:minValueValidationError"),
    maxLength: t("common:limitExceeded"),
  };

  return (
    <Modal open={isModalOpen}>
      <ModalWrapper
        sxProps={{
          maxHeight: "95vh",
          display: "flex",
          flexDirection: "column",
        }}
        data-testid="table-row-creation-modal"
      >
        <ModalHeader toggleModal={closeModal}>{TEXTS[mode].title}</ModalHeader>
        <ModalContent sx={{ p: "16px 24px", overflow: "auto" }}>
          <Form data-testid="table-row-creation-modal-form">
            {columns.map((column) => {
              const errorType = errors?.[column.id]
                ?.type as keyof typeof ERROR_MESSAGES;

              if (column.type.toUpperCase() === "COMPUTED") {
                return null;
              }

              if (column.type.toUpperCase() === "ENTITY") {
                return (
                  <UcmSelectWithController
                    key={column.id}
                    id={column.id}
                    control={control}
                    fullWidth
                    label={column.name}
                    options={(column.options || []).map((option) => ({
                      label: option.value,
                      value: option.id,
                    }))}
                    error={!!(errors?.[column.id]?.message || error)}
                    helperText={errorType ? ERROR_MESSAGES[errorType] : error}
                  />
                );
              }

              if (column.type.toUpperCase() === "POSITIVE_NUMBER") {
                return (
                  <TextFieldWithController
                    key={column.id}
                    id={column.id}
                    name={column.id}
                    fullWidth
                    control={control}
                    type="number"
                    label={column.name}
                    rules={{
                      min: 0,
                    }}
                    error={!!errorType || !!error}
                    helperText={errorType ? ERROR_MESSAGES[errorType] : error}
                    placeholder={t("common:numberInputPlaceholder")}
                    inputProps={{
                      min: 0,
                    }}
                  />
                );
              }

              return (
                <TextFieldWithCounter
                  fullWidth
                  disabled={mode === "delete"}
                  key={column.id}
                  maxLength={700}
                  multiline={true}
                  minRows={1}
                  id={column.id}
                  label={column.name}
                  control={control}
                  error={errorType ? ERROR_MESSAGES[errorType] : error}
                />
              );
            })}
          </Form>
        </ModalContent>
        <ModalFooter>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", gap: "16px" }}
          >
            <Button
              variant="text"
              onClick={closeModal}
              data-testid="table-row-creation-modal-cancel-button"
            >
              {t("common:cancel")}
            </Button>
            <Button
              variant="contained"
              disabled={
                mode !== "delete" && (isFormEmpty || !isDirty || !isValid)
              }
              onClick={handleSubmit(onSubmit)}
              data-testid="table-row-creation-submit-button"
            >
              {TEXTS[mode].actionButtonLabel}
            </Button>
          </Box>
        </ModalFooter>
      </ModalWrapper>
    </Modal>
  );
}
