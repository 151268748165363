import { ROLES, UserDto, UserWithRole } from "@applied-ai/types";
import { SetStateAction, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { getDisplayName } from "@applied-ai/utils";
import { SharedUser } from "./SharedUser";
import { useTranslation } from "next-i18next";
import { isUserExternal } from "./userRoleUtils";
import { ExternalsInput } from "./InviteUsersInput/ExternalsInput";

interface ExternalsTabProps<User> {
  isVisible: boolean;
  initialUsers: User[];
  getUsers: (q: string) => Promise<UserDto[]>;
  addUsers: (users: string[], role: ROLES, successCallback: () => void) => void;
  toggleParentVisibility: (isVisible: SetStateAction<boolean>) => void;
  removeUser: (
    userId: string,
    users: UserWithRole[],
    updateUsers: React.Dispatch<React.SetStateAction<User[]>>
  ) => () => void;
  canAssignExternal: boolean;
}

export const ExternalsTab = <User extends UserWithRole>({
  isVisible,
  initialUsers,
  getUsers,
  addUsers,
  toggleParentVisibility,
  removeUser,
  canAssignExternal,
}: ExternalsTabProps<User>) => {
  const [users, setUsers] = useState<User[]>(initialUsers);
  const { t } = useTranslation("common");
  const menuItems = [{ value: ROLES.EXTERNAL, label: t("externalVendor") }];
  const isOptionExternal = (user: UserDto, users: UserWithRole[]) =>
    !!users.find(
      (userWithRole) =>
        userWithRole.id === user.id && isUserExternal(userWithRole)
    );
  const usersToDisplay = users
    .sort((userA, userB) =>
      getDisplayName(userA).localeCompare(getDisplayName(userB))
    )
    .filter(isUserExternal);

  if (!isVisible) return null;

  return (
    <>
      <ExternalsInput
        initialUsers={initialUsers}
        users={users}
        setUsers={setUsers}
        getUsers={getUsers}
        getOptionDisabled={(user: UserDto, users: UserWithRole[]) =>
          isOptionExternal(user, users)
        }
        addUsers={addUsers}
        canAssignExternal={canAssignExternal}
      />
      {!!usersToDisplay.length && (
        <>
          <Divider sx={{ my: "16px" }} />
          <Box
            sx={{
              border: "1px solid",
              borderColor: "grey.100",
              borderRadius: 1,
              py: 1,
              mt: 2,
              gap: 1,
            }}
          >
            {usersToDisplay.map((user) => {
              return (
                <SharedUser
                  users={users}
                  modalTitle={t("deleteUser")}
                  roleToDisplay={t("externalVendors")}
                  toggleParentVisibility={() =>
                    toggleParentVisibility((prevValue) => !prevValue)
                  }
                  disabled={!canAssignExternal}
                  areActionsVisible={canAssignExternal}
                  avatar="invalid_link" // TODO: adjust that in https://appliedaide.atlassian.net/browse/UCTSX-334
                  key={user.id}
                  user={user}
                  canRemove={canAssignExternal}
                  remove={removeUser(user.id, users, setUsers)}
                  menuItems={menuItems}
                />
              );
            })}
          </Box>
        </>
      )}
    </>
  );
};
