import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { Box, TextField, Typography } from "../../atoms";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "next-i18next";
import { TextFieldProps } from "@mui/material";

export function TextFieldWithCounter<T extends FieldValues>({
  id,
  label,
  control,
  disabled,
  maxLength,
  required,
  error,
  ...props
}: {
  id: string;
  label: string;
  control: Control<T>;
  disabled: boolean;
  maxLength?: number;
  required?: boolean;
  error?: string;
} & Omit<TextFieldProps, "error">) {
  const { t } = useTranslation(["common"]);
  const [valueLength, setValueLength] = useState(
    control?._formValues?.[id]?.length || 0
  );

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValueLength(e?.target?.value?.length);
  };

  const inputPlaceholder = t("enterTextHere");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Controller
        name={id as Path<T>}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        control={control}
        rules={{
          maxLength,
          required,
          validate: (value) => (value ? !!value.trim() : true),
        }}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              disabled={disabled}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                onValueChange(e);
                field.onChange(e);
              }}
              label={label}
              placeholder={inputPlaceholder}
              error={!!error}
              inputProps={{
                ...(props.inputProps || {}),
              }}
              autoFocus={props.autoFocus}
              {...props}
              type="text"
            />
          );
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "4px",
        }}
      >
        <Typography
          variant="bodyRegular12"
          sx={{ color: "red.500", ml: "8px" }}
          data-testid="modal-entity-title-error-message"
        >
          {error}
        </Typography>
        {maxLength && (
          <Typography
            variant="bodyRegular12"
            sx={{ color: "grey.700" }}
            data-testid="modal-entity-title-length-counter"
          >
            {`${valueLength}/${maxLength}`}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
