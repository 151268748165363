import * as Sentry from "@sentry/nextjs";

const isProduction = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT !== "local";

export const SENTRY_CLIENT_CONFIG = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: isProduction,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  tracesSampleRate: 0.1,
  debug: false,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  feVersion: process.env.NEXT_PUBLIC_FE_VERSION,
};

export const SENTRY_SERVER_AND_EDGE_CONFIG = {
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: isProduction,
  tracesSampleRate: 0.1,
  debug: false,
  profilesSampleRate: 0.1,
};
