import { ROLES, UserDto, UserWithRole } from "@applied-ai/types";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Check from "@mui/icons-material/Check";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { useTranslation } from "next-i18next";
import { Button, TextField, Typography } from "../../../atoms";
import { Box, MenuItem } from "../../../reexports";
import { DataList } from "../../DataList/DataList";
import CheckIcon from "@mui/icons-material/Check";
import { useInviteUsers } from "./useAssignedUsers";
import { useState } from "react";

interface OwnersAndCollaboratorsInputProps<User> {
  initialUsers: User[];
  users: User[];
  setUsers: (users: User[]) => void;
  getUsers: (q: string) => Promise<UserDto[]>;
  getOptionDisabled: (user: UserDto, users: UserWithRole[]) => boolean;
  addUsers: (users: string[], role: ROLES, successCallback: () => void) => void;
  canAssignRoles: boolean;
  defaultRole?: ROLES;
  label?: string;
  hideChangeRoleSelect?: boolean;
}

export const OwnersAndCollaboratorsInput = <User extends UserWithRole>({
  initialUsers,
  users,
  setUsers,
  getUsers,
  addUsers,
  getOptionDisabled,
  canAssignRoles,
  defaultRole = ROLES.COLLABORATOR,
  label,
  hideChangeRoleSelect,
}: OwnersAndCollaboratorsInputProps<User>) => {
  const { t } = useTranslation("common");
  const [newUserRole, setNewUserRole] = useState<ROLES>(defaultRole);

  const {
    assignedUsers,
    assignedUsersIds,
    handleInviteUsersClick,
    handleAssignUsersClick,
    setAssignedUsers,
  } = useInviteUsers(newUserRole, users, setUsers, addUsers);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "8px",
      }}
    >
      <Box
        sx={{
          width: hideChangeRoleSelect ? "100%" : "60%",
          overflow: "hidden",
        }}
        data-testid="share-modal-email-or-name-input"
      >
        <DataList
          disabled={!canAssignRoles}
          value={assignedUsers}
          getOptions={getUsers}
          label={label}
          autoFocus
          getOptionLabel={(userDto: UserDto) =>
            `${userDto.details.email}_${userDto.details.display_name}`
          }
          isSelected={(option: UserDto, value: UserDto) =>
            assignedUsersIds.includes(option.id)
          }
          popupIcon={null}
          getOptionDisabled={(user: UserDto) => getOptionDisabled(user, users)}
          initialValue={initialUsers}
          listBoxDataTestId="owners-and-collaborators-autocomplete-options"
          renderOption={(props, option, state: { selected?: boolean }) => {
            const onClick = handleAssignUsersClick(option, state);
            return (
              <MenuItem
                {...props}
                onClick={onClick}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start !important",
                  }}
                >
                  <Typography variant="bodyMedium14">
                    {option.details.display_name}
                  </Typography>
                  <Typography variant="bodyMedium12" sx={{ color: "grey.600" }}>
                    {option.details.email}
                  </Typography>
                </Box>
                {state?.selected && (
                  <Box sx={{ marginLeft: "auto" }}>
                    <Check />
                  </Box>
                )}
              </MenuItem>
            );
          }}
          onChangeCallback={(users: User[] | UserDto[]) => {
            setAssignedUsers(users);
          }}
          renderTags={"TAGS"}
          placeholder={t("ownersAndCollaboratorsPlaceholder")}
          getTagLabel={(user) => user.details.display_name}
          icon={<SearchOutlined />}
          clearOnSelect={false}
        />
      </Box>
      <TextField
        select
        disabled={!canAssignRoles}
        value={newUserRole}
        onChange={(e) => setNewUserRole(e.target.value as ROLES)}
        variant="outlined"
        data-testid="share-modal-ownership-type-dropdown-button"
        SelectProps={{
          renderValue: (value) => {
            return (
              <span>
                {newUserRole.charAt(0).toUpperCase() + newUserRole.slice(1)}
              </span>
            );
          },
          IconComponent: ArrowDropDownIcon,
        }}
        sx={{
          display: hideChangeRoleSelect ? "none" : "flex",
          marginTop: "24px",

          ".MuiInputBase-root": {
            width: "128px",
            border: "none !important",
            boxShadow: "none !important",
            backgroundColor: "transparent",
            color: "grey.700",
            fontWeight: 500,
          },
          ".MuiInputBase-input": {
            paddingRight: "0px !important",
          },
        }}
      >
        {["owner", "collaborator"].map((role) => (
          <MenuItem
            key={role}
            value={role}
            sx={{
              minWidth: "180px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {role.charAt(0).toUpperCase() + role.slice(1)}
            {role === newUserRole && <CheckIcon />}
          </MenuItem>
        ))}
      </TextField>
      <Button
        variant="contained"
        sx={{
          marginTop: "24px",
          whiteSpace: "nowrap",
        }}
        onClick={handleInviteUsersClick}
        disabled={!canAssignRoles}
        data-testid="share-modal-invite-button"
      >
        {t("common:invite")}
      </Button>
    </Box>
  );
};
