import { ReactNode } from "react";
import {
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "../../reexports";
import { useTranslation } from "next-i18next";
import { Typography } from "../../atoms";

export const TableWrapper = styled(TableContainer)`
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.grey[200]};
  border-radius: 4px;
`;

export type Column = {
  id: string;
  label: string;
  numeric?: boolean;
  disablePadding?: boolean;
};

export function UcmTableHead({
  columns,
  sx,
}: {
  columns: Column[];
  sx?: SxProps;
}) {
  return (
    <TableHead sx={{ backgroundColor: "grey.50", ...sx }}>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sx={{
              p: "12px 16px",
            }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export const UcmTableBody = styled(TableBody)`
  tr:last-child td {
    border-bottom: 0;
  }

  td {
    color: ${({ theme }) => theme.palette.grey[900]};
  }
`;

export function UcmTable({
  children,
  withPagination = false,
  count,
  limit,
  offset,
  handleOffsetChange,
  handleLimitChange,
}: {
  children: ReactNode;
  withPagination?: boolean;
  count?: number;
  limit?: number;
  offset?: number;
  handleOffsetChange?: (offset: number) => void;
  handleLimitChange?: (limit: number) => void;
}) {
  const { t } = useTranslation("common");
  return (
    <TableWrapper>
      <Table data-testid="ucm-table">
        {children}
        {withPagination && (
          <TableFooter
            sx={{
              backgroundColor: "grey.50",
              borderTop: "1px solid",
              borderColor: "grey.200",
            }}
          >
            <TableRow sx={{ td: { borderBottom: 0 } }}>
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                labelRowsPerPage={
                  <Typography variant="bodyRegular14">
                    {t("common:rowsPerPage")}
                  </Typography>
                }
                labelDisplayedRows={({ from, to, count }) => (
                  <Typography variant="bodyRegular14">
                    {t("common:showingTablePagination", { from, to, count })}
                  </Typography>
                )}
                count={count as number}
                rowsPerPage={limit as number}
                page={offset as number}
                onPageChange={(_: unknown, newPage: number) => {
                  handleOffsetChange?.(newPage);
                }}
                onRowsPerPageChange={(
                  event: React.ChangeEvent<HTMLInputElement>
                ) => {
                  handleLimitChange?.(parseInt(event.target.value, 10));
                  handleOffsetChange?.(0);
                }}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableWrapper>
  );
}
