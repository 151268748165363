// eslint-disable-next-line no-restricted-imports
import { SnackbarOrigin } from "@mui/material";
import { create } from "zustand";

export enum ToastVariants {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
}

export interface ToastProps {
  id: string;
  type: ToastVariants;
  message?: string;
  position: SnackbarOrigin;
  callbackAction?: () => void;
  closeCallback?: () => void;
  actionLabel?: string;
  hideCloseIcon?: boolean;
  toastHideOffset?: number;
}

interface ToastStore {
  toasts: ToastProps[];
  addToast: (data: Omit<ToastProps, "id">) => void;
  removeToast: (id: string) => void;
}

export const useToastStore = create<ToastStore>((set) => ({
  toasts: [],
  addToast: (data) => {
    set((state) => ({
      toasts: [
        ...state.toasts,
        { ...data, id: String(state.toasts.length + 1) },
      ],
    }));
  },
  removeToast: (id: string) =>
    set((state) => ({
      toasts: state.toasts.filter((toast) => toast.id !== id),
    })),
}));
