import { alpha, styled } from "@mui/material";
import { Select as MuiSelect } from "../../reexports";

export const BaseSelect = styled(MuiSelect)`
  & .MuiOutlinedInput-notchedOutline {
    border: 0;
  }
  & {
    color: ${({ theme }) => theme.palette.grey[700]};
    background-color: ${({ theme }) => theme.palette.grey[50]};
    font-weight: 400;
    padding: 6px 8px 6px 12px;
    border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    border-radius: 4px;
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary[500]};
  }
  & .MuiInputBase-input {
    padding: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  &.Mui-focused {
    border: 1px solid ${({ theme }) => theme.palette.primary[500]};
    box-shadow: 0px 0px 4px 0px
      ${({ theme }) => alpha(theme.palette.primary[500], 0.6)};
  }

  // Input
  &.Mui-disabled {
    background-color: ${({ theme }) => theme.palette.grey[100]};
    cursor: not-allowed;
    -webkit-text-fill-color: ${({ theme }) => theme.palette.grey[700]};

    &::placeholder {
      -webkit-text-fill-color: ${({ theme }) => theme.palette.grey[500]};
    }

    &:hover {
      cursor: not-allowed;
      border: 1px solid ${({ theme }) => theme.palette.grey[300]};
    }
  }

  &.Mui-error {
    border: 1px solid ${({ theme }) => theme.palette.red[500]};

    &.Mui-focused {
      box-shadow: 0px 0px 4px 0px
        ${({ theme }) => alpha(theme.palette.red[500], 0.6)};
    }

    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.red[500]};
    }
  }
`;
