import { UserWithRole, ROLES } from "@applied-ai/types";

export const isUserApprover = (user: UserWithRole) =>
  user.role === ROLES.APPROVER;

export const isUserExternal = (user: UserWithRole) =>
  user.role === ROLES.EXTERNAL;

export const isUserOwnerOrCollaborator = (user: UserWithRole) =>
  user.role === ROLES.OWNER || user.role === ROLES.COLLABORATOR;
