import { create } from "zustand";

interface LoadingState {
  isLoading: boolean;
  setIsLoading: (isFetching: boolean) => void;
}

export const useLoadingState = create<LoadingState>((set) => {
  return {
    setIsLoading: (isFetching: boolean) =>
      set((state) => ({
        ...state,
        isLoading: isFetching,
      })),

    isLoading: false,
  };
});
