import { TextFieldProps, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Tooltip } from "../../Tooltip/Tooltip";
import { useEffect, useRef, useState } from "react";

const StyledTextField = styled(TextField)`
  justify-content: center;
  margin-right: 4px;

  & .MuiInputBase-root {
    border-radius: 2px;
  }

  & .Mui-error {
    background-color: ${(p) => !p.disabled && p.theme.palette.red[50]};
  }

  .MuiInputBase-input {
    height: 36px;
    padding: 0 0 0 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .Mui-disabled {
    -webkit-text-fill-color: unset !important;
    color: ${(p) => p.theme.palette.grey[900]};
  }

  .MuiFormHelperText-root.Mui-error {
    box-shadow: unset;
    background-color: unset;
    color: ${(p) => !p.disabled && p.theme.palette.red[500]};
  }

  .MuiInputBase-input:hover {
    cursor: ${(p) => (!p.disabled ? "pointer" : "default")};
    background-color: ${(p) => !p.disabled && p.theme.palette.primary[50]};
  }
`;

export const EditableTextField: React.FC<TextFieldProps> = ({
  value,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isOverflowed, setIsOverflowed] = useState(false);

  useEffect(() => {
    if (inputRef.current) {
      setIsOverflowed(
        inputRef.current.scrollWidth > inputRef.current.clientWidth
      );
    }
  }, [value]);

  return (
    <Tooltip
      title={isOverflowed ? (value as string) : ""}
      sx={{
        wordBreak: "break-all",
      }}
    >
      <StyledTextField
        variant="standard"
        defaultValue={value}
        InputProps={{
          disableUnderline: true,
          sx: {
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            fontWeight: 500,
            letterSpacing: "-0.2px",
            color: "grey.900",
            borderRadius: "50%",
          },
          inputRef: inputRef,
        }}
        {...props}
      />
    </Tooltip>
  );
};
